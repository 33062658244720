/**
 * Manages the filter data for job api requests. The object properties do not container the complete objects. They
 * contain the id or isoString representation only.
 */
export default class JobOccurrencesFilterData {

    /**
     * Main filter values with causes a reload after change
     */
    public dates: string[] = [];
    public users: string[] = [];

    public driverId: string[] = [];
    public managerId: string[] = [];
    public locationId: string[] = [];



    /**
     * Returns the http request query parameter for main filter values
     */
    public get queryData(): Array<{ date: string, query: string }> {
        /**
         * ?date=2019-11-05T15:32:07.854Z
         * &customer=5dc1494b6d52bd15b035d90a
         * &location=5dc1494b6d52bd15b035d90c&location=5dc1494b6d52bd15b035d90d
         * &user=5dc1494a6d52bd15b035d904&user=5dc1494a6d52bd15b035d905
         * &manager=615db3e8a570e00894e0601e
         */
        return this.dates.map((date) => {
            const users = this.users.length > 0 ? this.users.map((user) => `&user=${user}`).join('') : '';
            const driverId = this.driverId.length > 0 ? this.driverId.map((driversId) => `&driverId=${driversId}`).join('') : '';
            const managerId = this.managerId.length > 0 ? this.managerId.map((managersId) => `&managerId=${managersId}`).join('') : '';
            const locationId = this.locationId.length > 0 ? this.locationId.map((locationsId) => `&locationId=${locationsId}`).join('') : '';
            return {
                date,
                query: `date=${date}${users}${driverId}${managerId}${locationId}`,
            };
        });
    }
}
