








































































import {Component, Vue} from 'vue-property-decorator';
import JobsFilterData from '@/misc/JobOccurrencesFilterData';
import {namespace} from 'vuex-class';
import Throttle from '@/helper/Throttle';
import Job from '@/models/Job';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import SideCardComponent from '@/components/shared/SideCard.component.vue';
import JobDetailSidebarContentComponent from '@/components/job/JobDetailSidebarContent.component.vue';
import TimeSchedule from '@/models/TimeSchedule';
import JobManageComponent from '@/components/job/JobManage.component.vue';
import Location from '@/models/Location';
import moment, {Moment} from 'moment';
import {locationStoreActions, locationStoreGetter, locationStoreMutations} from '@/stores/location.store';
import {jobStoreActions, jobStoreGetter, jobStoreMutations} from '@/stores/job.store';
import {
  jobOccurrenceStoreActions,
  jobOccurrenceStoreGetter,
  jobOccurrenceStoreMutations,
} from '@/stores/jobOccurrence.store';
import JobOccurrence from '@/models/JobOccurrence';
import {Permission} from '@/misc/Enums/permission.enum';


const JobStore = namespace('job');
const LocationStore = namespace('location');
const JobOccurrenceStore = namespace('jobOccurrence');
const CustomerStore = namespace('customer');


@Component({
  components: {
    JobDetailSidebarContentComponent,
    SideCardComponent,
    JobFilterComponent: () => import(
        /* webpackChunkName: "JobFilterComponent" */
        '@/components/job/JobFilter.component.vue'),
    JobCalendarComponent: () => import(
        /* webpackChunkName: "JobCalendarComponent" */
        '@/components/job/JobCalendar.component.vue'),
    JobManageComponent: () => import(
        /* webpackChunkName: "JobManage" */
        '@/components/job/JobManage.component.vue'),
    ToggleButtonGroupComponent: () => import(
        '@/components/shared/ToggleButtonGroup.component.vue'),
  },
})
export default class JobsOverviewView extends Vue {

  @LocationStore.Getter(locationStoreGetter.LOCATION)
  public _location!: Location;
  @JobStore.Action(jobStoreActions.LOAD_JOB_ACTION)
  public loadJobAction!: (JobId: string) => Promise<Job>;
  @JobStore.Action(jobStoreActions.DELETE_JOB_ACTION)
  public deleteJobAction!: (JobId: string) => Promise<Job>;
  @LocationStore.Mutation(locationStoreMutations.STORE_LOCATION)
  public storeLocationMutation!: (location: Location | undefined) => void;
  @JobStore.Getter(jobStoreGetter.COLORS)
  public colors: any;
  @JobStore.Mutation(jobStoreMutations.STORE_COLORS)
  public storeColors!: (colors: any) => void;
  @LocationStore.Action(locationStoreActions.LOAD_LOCATIONS_ACTION)
  public loadLocations!: (payload: {tenantId: string}) => Promise<Location[]>;
  /**
   * Defines which date the calendar should focus
   */
  public focus: string | null = null;
  /**
   *  Defines the initial type of the calendar
   */
  public calendarType: 'week' | 'day' | null = null;
  /**
   *
   */
  public autoInsert: boolean = false;
  /**
   * boolean to reset the state of the job creation dialog
   */
  public resetJobDialog: boolean = false;
  public addJobDialog: boolean = false;
  public showJobCalendar: boolean = true;
  public location: Location = new Location();
  private groupBy: 'none' | 'customer' | 'employee' = 'none';
  @JobOccurrenceStore.Getter(jobOccurrenceStoreGetter.JOB_OCCURRENCES)
  private jobOccurrences!: JobOccurrence[];
  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.LOAD_JOB_OCCURRENCES_ACTION)
  private loadJobOccurrencesAction!: (payload: {tenantId: string, filterData: JobsFilterData, discardCache?: boolean }) => Promise<JobOccurrence[]>;
  @JobOccurrenceStore.Mutation(jobOccurrenceStoreMutations.CLEAR_JOB_OCCURRENCES)
  private clearJobOccurrences!: () => void;
  @JobOccurrenceStore.Mutation(jobOccurrenceStoreMutations.CLEAR_CACHED_JOB_OCCURRENCES)
  private clearCachedJobOccurrences!: () => void;
  /**
   * Filter data for work sessions
   */
  private filterData: JobsFilterData = new JobsFilterData();
  private throttle: Throttle;
  private showSideCard: boolean = false;
  private openDeleteDialog = false;

  /**
   * if true the jobOccurrence is not editable or deletable
   */
  private currentJobOccurrenceIsOver = true;

  private currentJobOccurrenceInfo: {
    jobOccurrence: JobOccurrence,
    userData: JobUserDataInterface,
  } | null = null;


  constructor() {
    super();
    this.throttle = new Throttle();
  }

  public async created() {

    await this.loadLocations({tenantId: this.$route.params.tenantId}).then((value) => {
      // Hide calender only when there are no locations and user has permission to create locations
      this.showJobCalendar = !(value.length <= 0 && this.$userRoleHandler.hasPermission(Permission.LOCATION_CREATE_ALL));
    });

    // reset all jobOccurrences in cache
    this.clearCachedJobOccurrences();
    // retrieves the filter data from url
    this.readUrl();
    // loads initial the data considers filter values
  }

  public onJobInfoChange(value: {
    jobOccurrence: JobOccurrence,
    userData: JobUserDataInterface,
  }) {
    this.currentJobOccurrenceInfo = value;
    this.currentJobOccurrenceIsOver = moment().isAfter(moment(value.jobOccurrence.startTime).endOf('day'));
    this.showSideCard = true;
    this.hideJobDialog();
  }

  /**
   * Is called if main filter data changes. Loads new data and discards jobOccurrence cache to get jobOccurrence data that
   * respects filter data
   */
  public loadJobs(data: JobsFilterData) {
    // merge filter data
    Object.assign(this.filterData, data);
    this.throttle.throttle(async () => {
      try {
        this.updateUrl();
        await this.loadJobOccurrencesAction({tenantId: this.$route.params.tenantId, filterData: this.filterData, discardCache: true});
      } catch (e) {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    });
  }

  /**
   * Event handler for calendar focus change
   */
  public onCalendarFocusChange(value: string) {
    this.focus = value;
    this.updateUrl();
  }

  /**
   * Event handler for calendar type change
   */
  public onCalendarTypeChange(value: 'week' | 'day') {
    this.calendarType = value;
    this.updateUrl();
  }

  /**
   *  Reads the url filter values and stores them in filterData
   */
  public readUrl() {
    // get main filter values
    if (this.$route.query.users) {
      this.filterData.users = (this.$route.query.users as string).split(',');
    }
    // get calendar values
    this.focus = this.$route.query.focus as string || null;
    this.calendarType = this.$route.query.calendarType as 'week' | 'day' || null;
  }

  /**
   * Hides the Job Dialog, if abort was pressed
   */
  public hideJobDialog(): void {
    this.addJobDialog = false;
  }

  /**
   * Updates the url values according to the calendar and filter values
   */
  public updateUrl() {
    const urlParams = new URLSearchParams();

    // defines values which should be considered in url
    const urlData: any = {
      users: this.filterData.users,
      focus: this.focus,
      calendarType: this.calendarType,
    };

    Object.keys(urlData)
        .filter((key) => urlData[key]) // no undefined values
        .filter((key) => !(Array.isArray(urlData[key]) && urlData[key].length === 0)) // no empty arrays
        .forEach((key) => urlParams.append(key, urlData[key]));

    // update url
    history.replaceState({}, '', this.$route.path + '?' + urlParams.toString());
  }

  public async onDeleteJob(jobId: string): Promise<void> {
    try {
      // Send API Request to delete the timeSchedule with the given id
      const deleted = await this.deleteJobAction(jobId);

      // If API send back a timeSchedule Document is probably deleted
      if (deleted) {
        // try to find the given timeSchedule in our location timeSchedules array
        const indexToDelete = this.location.jobs!.findIndex((job) => job.id === jobId);

        // If found delete from array
        if (indexToDelete > -1) {
          this.location.jobs!.splice(indexToDelete, 1);
        }

        // Show Success message
        this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.SUCCESS');

        // Clear Calendar Cache on Remove
        this.clearCachedJobOccurrences();

        // Close Delete Dialog
        this.openDeleteDialog = false;
        this.addJobDialog = false;
        await this.reloadCalender();

      }
      // catch all errors
    } catch (e) {
      // job was already deleted (by another user)
      if (e.status === 409) {
        this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.ERROR.RUNNING');
      } else {
        this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.ERROR.GENERAL');
      }
    }
  }

  public async addCreatedJob(job: Job): Promise<void> {
    // add timeSchedule to location
    this.location.jobs!.push(job);

    // update location in state
    this.storeLocationMutation(this.location);

    // Clear Jobs, so that the Calendar will reload its data
    await this.reloadCalender();
  }


  /**
   * Opens edit dialog of the timeSchedule. If edit is true, edit the selected TimeSchedule, if edit is false,
   */
  public async onUseJob(apiJob: Job, edit = true, useOrigin = false): Promise<void> {
    try {
      // get TimeSchedule from api
      let apiTimeSchedule = new TimeSchedule();
      await this.loadJobAction(apiJob.id!).then((value) => {
        apiTimeSchedule = value.timeSchedule;
      });


      // opens the dialog box
      this.showJobDialog(edit, apiJob, true);
    } catch (e) {
      if (e.status === 404) {
        // Shows an error message that the timeSchedule is already deleted and cannot be edited
        this.$notifyErrorSimplified('JOB_OVERVIEW.ERRORS.JOB_ALREADY_DELETED');
      } else {
        // Shows a generic error message that the timeSchedule cannot be edited
        this.$notifyErrorSimplified('JOB_OVERVIEW.ERRORS.COULD_NOT_EDIT_timeSchedule');
      }
    }
  }

  public showJobDialog(editMode: boolean = false, apiJob?: Job, insertAttributes = false): void {
    this.autoInsert = false;

    // Reset Job Dialog and show it in the end.
    this.resetJobDialog = true;
    this.addJobDialog = true;

    this.$nextTick(() => {
      this.resetJobDialog = false;

      // if job dialog should be opened in edit mode
      // Wait a dom update to tell the job manage component to do so
      if (insertAttributes) {
        this.autoInsert = true;

        // waits a dom update and inserts the existing timeSchedule data to the form
        this.$nextTick(() => {
          // Call the Edit method for the timeSchedule
          (this.$refs.jobManageComponent as JobManageComponent).setEditJob(apiJob!, editMode);
        });
      }
    });
  }


  // TODO after new design: implement more efficient reload
  public async reloadCalender(dates?: { startDate: Moment, endDate: Moment }) {
    if (dates) {
      this.filterData.dates = [];
      const startDate = dates.startDate.clone();
      for (let i = 0; i < 7; i++) {
        this.filterData.dates.push(startDate.toISOString());
        startDate.add(1, 'days');
      }
    }
    await this.loadJobOccurrencesAction({tenantId: this.$route.params.tenantId, filterData: this.filterData, discardCache: true});
  }

  private changeGroup(value: 'none' | 'customer' | 'employee') {
    this.groupBy = value;
  }
}
