import moment from 'moment';
import WorkSession from '@/models/WorkSession';
import Job from '@/models/Job';
import {getFormattedTime} from '@/helper/JobTimeHelper';
import JobOccurrence from '@/models/JobOccurrence';

/**
 * Support class which provides functions to determine the status of a job
 */
export class JobStatusIdentification {

    /**
     * Identifies the status of the given jobOccurrence
     */
    public static identify(jobOccurrence: JobOccurrence): JobStatusType {
        // check if any work sessions were started at all and if each assigned user has started a session
        const done = jobOccurrence.workSession;
        const startDate = moment(jobOccurrence.date!).hour(jobOccurrence.startTime.hour).minute(jobOccurrence.startTime.minute);
        if (!done) {
            // check if users are assigned
            if (!jobOccurrence.job.driver) {
                return JobStatusType.NOT_PLANNED;
                // check if job is in future
            } else if (startDate.isAfter(moment().utc(true))) {
                return JobStatusType.IN_FUTURE;
                // if not all user started their jobs, the event status is ambiguous
            } else {
                return JobStatusType.NOT_TOUCHED;
            }
        } else {
                return jobOccurrence.isFinished ? JobStatusType.DONE : JobStatusType.IN_PROGRESS;
        }
    }
}

/**
 * An enum which defines all available job status values
 */
export enum JobStatusType {
    NOT_PLANNED = 'NOT_PLANNED',
    IN_FUTURE = 'IN_FUTURE',
    NOT_TOUCHED = 'NOT_TOUCHED',
    DONE = 'DONE',
    IN_PROGRESS = 'IN_PROGRESS',
}
