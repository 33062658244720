




































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Job from '@/models/Job';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import UserInitialsComponent from '@/components/user/UserInitials.component.vue';
import Customer from '@/models/Customer';
import WorkSession from '@/models/WorkSession';
import i18n from '@/i18n';
import {namespace} from 'vuex-class';
import SignatureComponent from '@/components/shared/Signature.component.vue';
import User from '@/models/User';
import Location from '@/models/Location';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import TimeSchedule from '@/models/TimeSchedule';
import {JobStatusIdentification} from '@/misc/JobStatusIdentification';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import {TabItem} from '@/interfaces/TabItem';
import {showTimes, TimeDuration} from '@/helper/TimeDuration';
import {jobOccurrenceStoreActions} from '@/stores/jobOccurrence.store';
import {customerStoreActions} from '@/stores/customer.store';
import {locationStoreActions} from '@/stores/location.store';
import {jobStoreActions} from '@/stores/job.store';
import JobOccurrence from '@/models/JobOccurrence';
import moment from 'moment';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import TimeTrackingNotesComponent from '@/components/time-tracking/TimeTrackingNotes.component.vue';
import TimeTrackingTopicsComponent from '@/components/time-tracking/TimeTrackingTopics.component.vue';
import {getJobFrequency} from '@/helper/JobTimeHelper';

const JobStore = namespace('job');
const JobOccurrenceStore = namespace('jobOccurrence');
const CustomerStore = namespace('customer');
const LocationStore = namespace('location');

/**
 * This component represents the content of the side card used to display job details in the jobs overview.
 */
@Component({
  components: {
    TimeTrackingTopicsComponent,
    RJTextField,
    RJTabs,
    SignatureComponent,
    UserInitialsComponent,
    TimeTrackingNotesComponent,
  },
})
export default class JobDetailSidebarContentComponent extends Vue {

  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.LOAD_WORK_SESSION_ACTION)
  public loadWorkSessionAction!: (workSessionId: string) => Promise<WorkSession>;
  @CustomerStore.Action(customerStoreActions.LOAD_CUSTOMER_ACTION)
  public loadCustomerAction!: (customerId: string) => Promise<Customer>;
  @LocationStore.Action(locationStoreActions.LOAD_LOCATION_ACTION)
  private loadLocationAction!: (payload: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @JobStore.Action(jobStoreActions.LOAD_JOB_ACTION)
  public getJobAction!: (jobId: string) => Promise<Job>;
  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.LOAD_WORK_SESSION_IMAGES)
  public loadWorkSessionImages!: (workSessionId: string) => Promise<any>;

  /**
   * The current event to be displayed in the card. Matches the type of selectedEvent in the jobs overview
   */
  @Prop()
  public selectedEvent: {
    jobOccurrence: JobOccurrence,
    userData: JobUserDataInterface,
  } | null = null;
  public workSession: WorkSession = new WorkSession();

  /**
   * The currently selected customer. Null whenever a new customer is being loaded by selectedEvent watcher
   */
  private loadedLocation: Location | null = null;
  private loadedJob: Job | null = null;
  // expander
  private tabsModel: number = 0;
  private availableDays: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  private notesLength: number = 0;
  public photos?: any[] = [];

  private userConfig: UserInitialsConfig = {showTooltip: false, showFullName: true, big: true};

  constructor() {
    super();
  }

  get jobOccurrence(): JobOccurrence | null {
    return this.selectedEvent ? this.selectedEvent.jobOccurrence : null;
  }

  public get getTabItems(): TabItem[] {
    return [
      {
        key: 'employees',
        text: this.$t('GENERAL.EMPLOYEES').toString(),
        available: true,
      }, {
        key: 'topics',
        text: this.$t('LOCATION_MANAGE.TOPICS').toString(),
        available: !!this.selectedEvent?.jobOccurrence.workSession,
      }, {
        key: 'notes',
        text: this.$t('TIMETRACKING.HEADER.NOTES').toString(),
        available: !!this.selectedEvent?.jobOccurrence.workSession,
      },
    ];
  }

  public getJobFrequency = (timeSchedule: TimeSchedule) => getJobFrequency(timeSchedule, this.$i18n);


  get locale(): string {
    return i18n.locale;
  }

  public showTime = (time: TimeDuration) => showTimes(time);

  /**
   * When the selected event changes, update all information not immediately contained in the event.
   * @private
   */
  @Watch('selectedEvent')
  private async onEventChanged() {
    if (this.jobOccurrence) {
      await this.loadLocationAction({
        locationId: this.jobOccurrence?.job.location!.id as string,
        shouldBeStored: true,
      }).then((value) => {
        this.loadedLocation = value;
      });

      this.getJobAction(this.selectedEvent?.jobOccurrence.job.id!).then((value) => {
        this.loadedJob = value;
      });

      if (this.selectedEvent?.jobOccurrence.workSession) {
        await this.loadWorkSessionAction(this.selectedEvent?.jobOccurrence.workSession)
            .then((value) => this.workSession = value);
        await this.loadWorkSessionImages(this.workSession.id).then((value) => {
          this.photos = value.records;
        });
        this.notesLength = this.workSession.notes?.length ?? 0;
      }
    }
  }

  // TODO: Rename 'Dashboard' to 'Details'. Make constants for view names. Make one Method for routing here
  public onUserClick(user: User) {
    this.$router.push({
      name: 'userDetails', params: {
        tenantId: this.$route.params.tenantId,
        userId: user.id!,
      },
    });
  }

  private onLocationClick() {
    // If something is not loaded, don't change the place
    if (!this.loadedLocation?.customer) {
      return;
    }
    this.$router.push({
      name: 'locationDashboard',
      params: {
        tenantId: this.$route.params.tenantId,
        locationId: this.loadedLocation!.id!,
      },
    });
  }

  public getDurationHumanized(duration: number) {
    const d = moment.duration(duration);
    return `${d.hours()}:${(d.minutes() + '').padStart(2, '0')} ${this.$t('GENERAL.UNITS.ABBRV.HOUR')}`;
  }

  public onReloadNotes(notesLength: number) {
    this.notesLength = notesLength;
  }

  public getStatus() {
    return JobStatusIdentification.identify(this.jobOccurrence!);
  }
}
